import React from 'react';
import styles from './Feedback.module.css';

interface IComment {
    datetime: string,
    user: {
        name: string,
    },
    text: string,
}

interface IFeedbackItem {
    forOrder: {
        ofDate: string,
    },
    feedback: IComment,
    reply: IComment | null,
}


interface IFeedbackItemProps {
    item: IFeedbackItem,
}

function FeedbackItem(props: IFeedbackItemProps) {
    const { item } = props
    return <div style={{ textAlign: 'left', }}>
        <div>
            <strong>{item.feedback.user.name}</strong>
            <span> {new Date(item.feedback.datetime).toLocaleDateString()} </span>
            <span style={{ fontSize: '.5em', color: '#888' }}> (за заказ от {new Date(item.forOrder.ofDate).toLocaleDateString()}) </span>
        </div>
        <div><p style={{ textShadow: '1px -1px 1px rgba(255,255,255,0.4)' }}>{item.feedback.text}</p></div>
    </div>
}

export type IFeedbackItems = IFeedbackItem[]

interface IFeedbackProps {
    feedbackComments: IFeedbackItem[]
}

export default function Feedback(props: IFeedbackProps) {
    const { feedbackComments } = props
    return <div className={styles.feedbackBlock}>
        <h1>Обратная связь</h1>
        {
            feedbackComments.map((item, index) => {
                return <FeedbackItem item={item} key={index} />
            })
        }
    </div>
};