import React from 'react';
import styles from './Footer.module.css';

import {Link} from 'react-router-dom';

const Footer = () => {
    return (
<footer>
    <div className={styles.footerMenu}>
        <Link to="/candles/" className={styles.menuItem}>Декор</Link>
        {/* <Link to="/create_order" className={styles.menuItem}>Сканирование фотоплёнок</Link> */}
        {/* <a href="#" className={styles.menuItem}>Оцифровка видеокассет</a>
        <a href="#" className={styles.menuItem}>Доставка и оплата</a> */}
        <Link to="/contacts/" className={styles.menuItem}>Контакты</Link>
    </div>
    <div className={styles.footerDown}>
        <div className={styles.copyRight}>
            ©DoLiKa 2001 - 2022 <span style={{"fontSize":"4pt"}}>(v0.3)</span>
        </div>
        <div className={styles.contactSmall}>
            <a href="mailto:manager@dolika.ru" target="_blank">manager@dolika.ru</a>
        </div>
    </div>
</footer>
    )
}

export default Footer;