import React, { Fragment, ChangeEvent, ChangeEventHandler, useMemo } from 'react';


interface IButton {
    value: string;
    label: string;
}

interface IButtonGroup {
    buttons: IButton[];
    name: string;
    value: string;
    onChange: ChangeEventHandler;
}

function ButtonGroup(props:IButtonGroup) {
    const randomID = useMemo(()=>Math.random().toString(36).substring(2, 15),[props.name, props.buttons])
    return (
        <div className="btn-group" role="group" aria-label="Выберите услугу">
            {
                props.buttons.map((button:IButton, index)=>{
                    const inputID = [props.name, button.value, index, randomID].join("-")
                    return (<Fragment key={index}>  
                        <input type="radio" name={props.name} autoComplete="off"
                            id={inputID}
                            value={button.value} 
                            checked={button.value === props.value}
                            onChange={props.onChange}/>
                        <label className="button" htmlFor={inputID}>{button.label}</label>
                        </Fragment>
                    );
                })
            }
        </div>
    );
}

export default ButtonGroup;