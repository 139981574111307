import React from 'react';
import './style.css';

function Contacts(){
    return (
<div className="content fix-width contentPadding contactsPage">
    <h2>Как с нами связаться</h2>
    <div className="contacts">
        <span>Email:</span>
        <a href="mailto:manager@dolika.ru">manager@dolika.ru</a>
        <span>Telegram:</span>
        <a href="http://t.me/dolika_chat_bot">@dolika_chat_bot</a>
        <span>Телефон:</span>
        <a href="tel:+79049880855" target="_blank">+7 (904) 98-55-641</a>
    </div>
</div>
);

}
export default Contacts;