import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Index from './pages/index/index'
import CreateOrder from './pages/create_order/index'
import GetOrder from './pages/order/index'
import Contacts from './pages/contacts'
import Shop from './pages/shop'

import Header from './UI/Header'
import Footer from './UI/Footer'

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="wrapper">
          <Header />
          <Routes>
            <Route path='/create_order' element={<CreateOrder />} />
            <Route path='/order/:id' element={<GetOrder />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/candles' element={<Shop />} />
            <Route path='/' element={<Index />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const container = document.getElementById("root") as Element;
const root = createRoot(container)
root.render(<App />);
