import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import ButtonGroup from 'UI/ButtonGroup';
import Modal from 'UI/Modal';

const ServiceSelector = {
    buttons: [
        {
            value: "negatives",
            label: "Негативы",
        },
        {
            value: "slides",
            label: "Слайды",
        },
        {
            value: "video",
            label: "Видеокассеты",
        },
    ],
    name: "service-selector",
}

const TransferMethodSelector = {
    buttons: [
        {
            value: "courier",
            label: "Курьер на адрес",

        },
        {
            value: "post",
            label: "Отправить почтой",
        },
        {
            value: "in-person",
            label: "Передать лично",
        },
    ],
    name: "transfer-method",
}


const CreateOrder = () => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        service: "negatives",
        quality: "600",
        transfer: "in-person",
        customerName: "",
        customerPhone: "",
    })

    const [orderid, setOrderID] = useState()

    function onSubmit(e: FormEvent) {
        console.log(state);
        e.preventDefault();
        fetch("/api/v1/order/create", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            body: JSON.stringify(state),
        }).then(function (response: Response) {
            if (response.ok) {
                return response.json()
            } else {
                console.log("FAIL");
            }
            console.log(response);
        }).then((obj) => {
            console.log("setting order id to %s", obj["id"])
            const order_id = obj["id"]
            setOrderID(order_id)
        }).catch(function (reason: any) {
            console.log("catch");
            console.log(reason);
        })
    }

    function onDpiChange(e: ChangeEvent<HTMLSelectElement>) {
        console.log(e.target.value)
        setState({
            ...state,
            quality: e.target.value,
        })
    }

    function onCustomerNameChange(e: ChangeEvent<HTMLInputElement>) {
        setState({
            ...state,
            customerName: e.target.value,
        })
    }

    function onCustomerPhoneChange(e: ChangeEvent<HTMLInputElement>) {
        setState({
            ...state,
            customerPhone: e.target.value,
        })
    }

    function onServiceChange(e: ChangeEvent<HTMLInputElement>) {
        setState({
            ...state,
            service: e.target.value,
        })
        console.log(e.target.value)
    }

    function onTransferChange(e: ChangeEvent<HTMLInputElement>) {
        setState({
            ...state,
            transfer: e.target.value,
        })
        console.log(e.target.value)
    }

    return (
        <div className="content fix-width create-order-content">
            <h1>Оформление заказа</h1>
            <form action="order.html" onSubmit={onSubmit}>
                <h3><label htmlFor="service-selector">Выберите услугу</label></h3>
                <ButtonGroup
                    buttons={ServiceSelector.buttons}
                    name={ServiceSelector.name}
                    value={state.service}
                    onChange={onServiceChange}
                />
                {state.service == "video" ? null : <h3><label htmlFor="dpi-selector">Выберите качество</label></h3>}
                {state.service == "video" ? null :
                    <select id="dpi-selector" name="dpi-selector" onChange={onDpiChange} value={state.quality}>
                        <option label="300 dpi" value="300">300</option>
                        <option label="600 dpi" value="600">600</option>
                        <option label="1200 dpi" value="1200">1200</option>
                        <option label="2400 dpi" value="2400">2400</option>
                        <option label="3600 dpi" value="3600">3600</option>
                        <option label="7200 dpi" value="7200">7200</option>
                    </select>
                }
                <h3><label htmlFor="transfer-method">Способ передачи плёнки</label></h3>
                <ButtonGroup
                    buttons={TransferMethodSelector.buttons}
                    name={TransferMethodSelector.name}
                    value={state.transfer}
                    onChange={onTransferChange}
                />
                <h3>Покупатель</h3>
                <h4><label htmlFor="customer-name">Имя</label></h4>
                <input type="text" name="customer-name" id="customer-name" onChange={onCustomerNameChange} required />
                <h4><label htmlFor="customer-phone">Телефон</label></h4>
                <input type="tel" name="customer-phone" id="customer-phone" onChange={onCustomerPhoneChange} required />
                <br />
                <input type="submit" value="Оформить заказ" className="button button-primary" />
            </form>
            {
                orderid != null ?
                    <Modal onClickAway={() => {
                        navigate(`/order/${orderid}`);
                    }}>
                        <div>
                            <p>Спасибо, ваш заказ принят в работу. </p>
                            <p>С вами скоро свяжутся.</p>
                            <span>
                                Ваш заказ номер &nbsp;
                            </span>
                            <span>
                                <Link to={`/order/${orderid}`}>{orderid} </Link>
                            </span>
                        </div>
                    </Modal>
                    : null
            }

        </div>
    );
}

export default CreateOrder;