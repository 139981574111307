import './style.css';
import negativeFilm from './negative.webp';
import imgTobaccoVanila from './tobaco-vanilla-2.webp'

import { Link } from 'react-router-dom';

import Feedback, { IFeedbackItems } from 'UI/Feedback';


const feedbackComments: IFeedbackItems = [
    {
        forOrder: {
            ofDate: '2022-05-10',
        },
        feedback: {
            datetime: '2022-05-12T12:38:00Z',
            user: {
                name: 'Юлия',
            },
            text: 'Спасибо за оцифрованные видеокассеты и фотоплёнки. Было приятно посмотреть на них спустя 20 лет',
        },
        reply: null,
    },
]


function Index() {
    return (
        <div className="content">
            <div className="scan-photo-block-bg">
                <div className="scan-photo-block">
                    <div className="scan-photo-block-left">
                        <h1>Свечи в гипсе и стекле</h1>
                        <p>Украсьте свой интерьер и проведите приятный вечер с ароматическими свечами</p>
                        <div className="order-buttons">
                            <a href="/candles" className="button button-primary">Купить от 350 рублей</a>
                        </div>
                    </div>
                    <div className="scan-photo-block-right">
                        <img
                        src={imgTobaccoVanila}
                        alt="табак-ваниль"
                        />
                    </div>
                </div>
            </div>
            <div className="scan-photo-block-bg">
                <div className="scan-photo-block">
                    <div className="scan-photo-block-left">
                        <h1>Сканирование фотоплёнок</h1>
                        <p>
                            Отбросьте все переживания, что счастливые моменты вашей жизни могут быть утеряны.
                        </p>
                        <div className="order-buttons">
                            <Link to="/create_order" className="button button-primary">Оформить заказ</Link>
                            {/*<Link to="/create_order" className="button button-secondary">Подробнее</Link>*/}
                        </div>
                    </div>
                    <div className="scan-photo-block-right">
                        <img
                            src={negativeFilm}
                            alt="negative"
                        />
                    </div>
                </div>
            </div>
            <div className="dvr-block">
                <h1>Оцифровка видеокассет</h1>
                <p>
                    Оцифровка видеокассет – это процесс перевода видеозаписи в цифровой формат.
                    Мы оцифруем ваш архив в любой удобный для вас формат по низким ценам.
                    На данный момент мы поддерживаем кассеты формата VHS.
                    Чтобы получить консультацию и заказать услугу оцифровка видеокассета формата VHS позвоните нам по телефону или оставьте заявку на нашем сайте.
                </p>
                <div className="order-buttons">
                    <Link to="/create_order" className="button button-primary">Оформить заказ</Link>
                    {/* <a href="create_order.html" className="button button-secondary">Подробнее</a> */}
                </div>
            </div>
            <div className="feedback-block">
                <Feedback feedbackComments={feedbackComments} />
            </div>
        </div>
    )
}

export default Index;