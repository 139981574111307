import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'

import styles from './Shop.module.css';

console.log(styles);

interface ICarouselProps {
    children: any
}
function Carousel(props: ICarouselProps) {
    const children = props.children;
    const [index, setIndex] = useState(0)
    const setNext = () => {
        console.log(`setting next image ${index} of ${props.children.length}`);
        console.log(props.children)
        setIndex((index + 1) % children.length);
    }
    const setPrev = () => {
        console.log("setting prev image");
        console.log(index)
        setIndex((index + children.length - 1) % children.length);
    }
    return <div className={styles.carousel}>
        <div className={styles.carouselInner}>
            {children[index]}
        </div>
        <button className={styles.carouselControlPrev} type="button" onClick={setPrev} >
            <span className={styles.carouselControlPrevIcon} aria-hidden="true"></span>
            <span className={styles.visuallyHidden}>Предыдущий</span>
        </button>
        <button className={styles.carouselControlNext} type="button" onClick={setNext}>
            <span className={styles.carouselControlNextIcon} aria-hidden="true"></span>
            <span className={styles.visuallyHidden}>Следующий</span>
        </button>
    </div>;
}

function Card({ card }: { card: ICard }) {
    return (
        <div className={styles.cardRow}>
            <div className={styles.imageCarousel}>
                <Carousel>
                    {card.images.map((image, index) => {
                        return <img key={index} src={image.url} className={styles.cardImage} />;
                    })}
                </Carousel>
            </div>
            <div className={styles.titleAndButtons}>
                <h5 className={styles.cardTitle}>{card.title}</h5>
                <div className={styles.btnGroup} role="group" aria-label="">
                    <a href={card.buyLink} className={`${styles.buyLink} button button-primary`}>Купить от {card.price}&nbsp;₽</a>
                    <a href={card.reviewLink} className={`${styles.reviewLink} button button-secondary`}>Оставить отзыв</a>
                </div>
            </div>
        </div>
    );
};

interface ICard {
    title: string
    price: string
    images: {
        url: string
    }[]
    buyLink: string
    reviewLink: string
};

interface IShopPage {
    cards: ICard[],
};

const Shop = () => {
    const [shop, setShop] = useState<IShopPage>(
        { cards: [] }
    )

    useEffect(() => {
        fetch(`/api/v1/ozon/offers`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            },
            redirect: 'follow',
        }).then(function (response: Response) {
            if (response.ok) {
                return response.json()
            } else {
                console.log("error")
            }
        }).then((obj) => {
            obj && obj.cards && setShop({
                ...shop,
                cards: obj.cards,
            })
        }).catch
    }, [])

    return (<div className={styles.shopTop}>
        <h2>Товары для вашего уюта</h2>
        <div>
            <div className={styles.col}>
                {
                    shop.cards.map((card: ICard, index) => { return <Card key={index} card={card} /> })
                }
            </div>
        </div>
    </div>);

}

export default Shop;