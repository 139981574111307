import React from 'react';
import styles from './Modal.module.css';

function Modal(props: any) {
    return (
        <div className={styles.blackOverlay} onClick={props.onClickAway}>
            <div className={styles.verticalAligner} onClick={(e) => { e.stopPropagation() }}>
                <div className={styles.whiteWindow}>
                    <div className={styles.modalContent}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;