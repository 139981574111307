
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

const ServiceSelector = {
    buttons: [
        {
            value: "negatives",
            label: "Негативы",
        },
        {
            value: "slides",
            label: "Слайды",
        },
        {
            value: "video",
            label: "Видеокассеты",
        },
    ],
    name: "service-selector",
}

const TransferMethodSelector = {
    buttons: [
        {
            value: "courier",
            label: "Курьер на адрес",

        },
        {
            value: "post",
            label: "Отправить почтой",
        },
        {
            value: "in-person",
            label: "Передать лично",
        },
    ],
    name: "transfer-method",
}

interface IOrder {
    loaded: boolean,
    error: number | null,
    orderId: string,
    service: string,
    quality: string,
    transfer: string,
    customerName: string,
    customerPhone: string,
}

const GetOrder = () => {
    const navigate = useNavigate();

    const { id: apiOrderId } = useParams()

    const [state, setState] = useState<IOrder>({
        loaded: false,
        error: null,
        orderId: "",
        service: "",
        quality: "",
        transfer: "",
        customerName: "",
        customerPhone: "",
    })

    useEffect(() => {
        fetch(`/api/v1/order/get/${apiOrderId}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            },
            redirect: 'follow',
        }).then(function (response: Response) {
            if (response.ok) {
                return response.json()
            } else {
                setState({
                    ...state,
                    error: response.status,
                })
            }
            console.log(response);
        }).then((obj) => {
            const order = obj.order
            setState({
                ...state,
                customerName: order.customerName,
                customerPhone: order.customerPhone,
                quality: order.quality,
                service: order.service,
                transfer: order.transfer,
                loaded: true,
            })
        }).catch
    }, [])

    if (state.error && state.error != 200) {
        return <div className="content fix-width create-order-content">
            {
                state.error == 404 ? <span>Заказ {apiOrderId} не найден</span> : <span>Ошибка получения информации о заказе. Повторите попытку позже</span>
            }
        </div>
    }
    if (state.loaded) {
        return (
            <div className="content fix-width create-order-content">
                <h1>{state.customerName},</h1>
                <h2>ваш заказ № {apiOrderId}</h2>
                <div>
                    <p>Услуга: {serviceNameForCode(state.service)} {state.quality && <span>в качестве {state.quality} dpi</span>} </p>
                    <p>Способ передачи оригиналов: {transferMethodFromCode(state.transfer)}</p>
                    <p>Менеджеры свяжутся с вами в ближайшее время по телефону <strong>{state.customerPhone}</strong></p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="content fix-width create-order-content">
                Загружаю информацию о заказе {apiOrderId}
            </div>
        )
    }
}

export default GetOrder;

function serviceNameForCode(service: string): React.ReactNode {
    return ServiceSelector.buttons.find((value) => value.value === service)?.label
}
function transferMethodFromCode(transfer: string): React.ReactNode {
    return TransferMethodSelector.buttons.find((value) => value.value === transfer)?.label
}

